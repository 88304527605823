<template lang="pug">
.order

  .order-title
    h1 Compra # {{ order.id }}

    .order-status {{ order.status }}

    .order-date {{ localizedDate(order.createdAt) }}

  .order-payment(v-if="order.bankSlipUrl || order.pix")
    PendingPayment(
      :bankSlipUrl="order.bankSlipUrl",
      :pix="order.pix"
    )

  .order-shipping-address

    h2 Endereço de entrega
    | {{ order.shippingAddress.street }}
    | , {{ order.shippingAddress.number }}
    span(v-if="order.shippingAddress.complement") , {{ order.shippingAddress.complement }}
    br
    | {{ order.shippingAddress.district }}
    br
    | {{ order.shippingAddress.city }}
    | / {{ order.shippingAddress.state }}
    br
    | {{ order.shippingAddress.postalCode }}

  .order-details
    h2 Detalhes da compra

    table
      tbody

        tr
          td Produtos
          td R$ {{ centsToString(order.subtotal) }}

        tr
          td Frete
          td R$ {{ centsToString(order.shippingCost) }}

    table.total
      tbody
        tr
          td Total
          td R$ {{ centsToString(order.total) }}
        tr
          td
          td {{ order.paymentMethod }}

    .order-actions
      Button(
        label="Comprar novamente",
        size="small",
      )

  .order-packages
    h2 Produtos

    .order-package-list

      .order-package
        .order-package-title
          h3 Pacote # 9823


        .order-package-status Pacote entregue

        .order-package-product-list
          .order-package-product(v-for="item in order.items")
            .order-package-product-image
              CdnImage(
                :src="item.thumbnail?.fileName",
                :width="124",
                :height="124",
                :quality="75"
              )

            .order-package-product-details
              .order-package-product-description {{ item.description }}

              .order-package-product-quantity

              .order-package-product-price
                | {{ item.quantity }} x
                | R$ {{ centsToString(item.unitPrice) }}

        .order-package-actions
          Button(
            type="text",
            label="Rastrear envio",
          )

          Button(
            type="text",
            label="Ver nota fiscal",
            disabled
          )
</template>
<script lang="ts" setup>
import OrderService from '~/services/ecommerce/orderService'

import PendingPayment from './PendingPayment.vue'

definePageMeta({
  requiresAuth: true
})

const route = useRoute()

const orderId = computed(() => parseInt(route.params["orderId"] as string))

const { data: order, pending, error, refresh } = await useAsyncData(() => OrderService.findOrder(orderId.value))

reportOnError(error, 'Failed to load order data')
</script>
<style lang="sass" scoped>
@import '~/assets/styles/mixins'
@import '~/assets/styles/variables'

.order
  display: grid
  width: 100%
  max-width: 1400px
  padding: 0 16px
  margin: 0 auto
  grid-template-areas: "title title" "payment details" "shipping-address details" "packages details"
  grid-template-columns: 1fr auto
  justify-items: start
  align-items: start
  gap: 16px

  @include tablet
    grid-template-areas: "title" "payment" "shipping-address" "packages" "details"
    grid-template-columns: 1fr

  .order-title
    grid-area: title

    h1
      margin: 0 0 8px 0

    .order-status
      color: $magenta


  .order-payment
    grid-area: payment

  .order-details
    grid-area: details
    display: flex
    flex-direction: column
    justify-content: flex-start
    background-color: $beige
    border-radius: 8px
    padding: 16px
    width: 420px

    @include tablet
      width: 100%

    h2
      margin: 0 0 18px 0
      font-size: 16px

    table
      tr
        td
          &:first-child
            font-weight: bold

          &:last-child
            text-align: right

      &.total
        font-size: 18px
        margin-top: 8px
        padding-top: 8px
        border-top: 1px solid black


  .order-shipping-address
    grid-area: shipping-address
    background-color: $beige
    border-radius: 8px
    padding: 16px
    width: 100%
    font-size: 14px


    h2
      margin: 0 0 18px 0
      font-size: 16px

  .order-packages
    grid-area: packages
    width: 100%

    .order-package-list
      display: flex
      flex-direction: column
      width: 100%

      .order-package
        display: flex
        flex-direction: column
        width: 100%

        .order-package-title
          h3
            font-size: 16px
            margin: 0 0 8px 0

        .order-package-status
          color: $magenta
          margin-bottom: 16px
          font-size: 16px


        .order-package-product-list
          display: flex
          flex-direction: column
          gap: 0

          .order-package-product
            display: grid
            grid-template-areas: "image details"
            grid-template-columns: 124px 1fr
            gap: 16px
            background-color: $beige
            padding: 12px
            border-top: 1px solid #000

            &:first-child
              border-top-left-radius: 8px
              border-top-right-radius: 8px
              border-top: none

            &:last-child
              border-bottom-left-radius: 8px
              border-bottom-right-radius: 8px


            .order-package-product-image
              grid-area: image
              width: 124px
              height: 124px
              border-radius: 8px
              overflow: hidden
              background-color: #FFF

            .order-package-product-details
              grid-area: details
              display: flex
              flex-direction: column
              justify-content: flex-start
              gap: 8px

              .order-package-product-description
                font-size: 14px
                font-weight: bold

              .order-package-product-price
                margin: 0


        .order-package-actions
          display: flex
          flex-direction: row
          justify-content: flex-start
          gap: 8px
          margin-top: 8px
</style>