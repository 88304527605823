<template lang="pug">
.pending-payment
  .pix(v-if="pix != null")
    h2 Realize o pagamento

    p Realize o pagamento da sua compra com o Pix abaixo.

    p
      b Aviso:&#32;
      | Pix válido por 30 minutos. A confirmação do seu pagamento pode levar alguns minutos.

    .pix-options
      .pix-copy-paste
        h3 Pix copia e cola
        p Clique aqui para copiar o seu código Pix:

        .copy-paste(@click="copyPix")
          fa(:icon="['far', 'copy']")
          | &#32;Pix Copia e Cola

        p Ou copie o código abaixo:

        pre {{ pix }}

      .pix-qrcode
        h3 Pix QR Code
        p Alternativamente, você pode escanear o QR Code abaixo:

        .qrcode-container
          .qrcode
            qrcode-vue(:value="pix", :size="200", level="H")

  .bank-slip(v-else-if="bankSlipUrl != null")
    h2 Realize o pagamento

    p Realize o pagamento da sua compra com o boleto abaixo.

    p
      b Aviso:&#32;
      | O boleto é válido por 3 dias. A confirmação do seu pagamento pode levar até 3 dias úteis.

    Button(
      label="Ver boleto bancário",
      :to="bankSlipUrl",
      :internal="false",
      target="_blank"
    )
</template>
<script lang="ts" setup>
import QrcodeVue from 'qrcode.vue'

const props = defineProps({
  pix: {
    type: String
  },
  bankSlipUrl: {
    type: String
  }
})

const notifications = useNotifications()

const copyPix = () => {
  console.log('copyPix')
  const text = props.pix ?? ""

  if (navigator.clipboard) {
    navigator.clipboard.writeText(text)

    notifications.info('Código Pix copiado!')
  } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const textarea = document.createElement('textarea')
    textarea.textContent = text
    textarea.style.position = 'fixed' // Prevent scrolling to bottom of page in MS Edge.
    document.body.appendChild(textarea)
    textarea.select()

    try {
      document.execCommand('copy')
      notifications.info('Código Pix copiado!')
    } catch (ex) {
      console.warn('Copy to clipboard failed.', ex)
      notifications.error('Não foi possível copiar o código Pix!')
    } finally {
      document.body.removeChild(textarea)
    }
  }
}
</script>
<style lang="sass" scoped>
@import '~/assets/styles/mixins'
@import '~/assets/styles/variables'

.pending-payment

  .pix

    .pix-options
      display: grid
      grid-template-areas: 'qrcode copy-paste'
      grid-template-columns: 1fr 1fr
      grid-gap: 32px

      @include tablet
        grid-template-areas: 'copy-paste' 'qrcode'
        grid-template-columns: 1fr

      .pix-copy-paste
        grid-area: copy-paste

        .copy-paste
          position: relative
          display: block
          width: 240px
          margin: 32px auto
          padding: 16px 32px
          font-size: 20px
          text-align: center
          border-radius: 48px
          background-color: $acquaBlue
          cursor: pointer
          user-select: none

          &::before
            position: absolute
            display: block
            top: 4px
            left: 4px
            width: calc(100% - 8px)
            height: calc(100% - 8px)
            border: dashed 2px black
            border-radius: 48px
            content: ''
            box-sizing: border-box

        pre
          display: block
          margin: 0 auto
          padding: 16px
          font-size: 20px
          text-align: left
          border-radius: 8px
          background-color: $beige
          white-space: normal
          overflow-wrap: anywhere

          @include phablet
            font-size: 16px

      .pix-qrcode
        grid-area: qrcode

        .qrcode-container
          position: relative
          display: block
          width: 296px
          margin: 32px auto
          padding: 32px
          font-size: 20px
          text-align: center
          border-radius: 8px
          background-color: $beige

          .qrcode
            position: relative
            width: 232px
            padding: 16px
            background-color: white
            border-radius: 8px
</style>